import { Component, Inject, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfBrand from "@/vue/domain/brand/df-brand";
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfStoreSelectionComponent = () => Utils.externalComponent2("df-store-selection");

@Component({
  components: {
    DfStoreCard: DfStoreCardComponent,
    DfStoreSelection: DfStoreSelectionComponent,
    DfFooter: () => Utils.externalComponent2("df-footer"),
  },
})
export default class DfPageStoreSelectionComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private selectionMode = false;

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get backgroundImageUrl(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_BACKGROUND_MOBILE, `${Utils.getPublicPath()}/assets/background_mobile.png`);
  }

  get backgroundImagePosition(): string {
    let backgroundPosition: string;
    const position: string = Utils.getPropertyValue(this.brand, Utils.PROPERTY_BACKGROUND_POSITION, "STRING");
    switch (position) {
      case "TOP":
        backgroundPosition = "top";
        break;
      case "BOTTOM":
        backgroundPosition = "bottom";
        break;
      case "LEFT":
        backgroundPosition = "left";
        break;
      case "RIGHT":
        backgroundPosition = "right";
        break;
      default:
        backgroundPosition = "center";
    }
    return backgroundPosition;
  }

  get brandLogo(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_LOGO, `${Utils.getPublicPath()}/assets/logo.png`);
  }

  get brandLogoHeader(): string {
    return Utils.getPropertyImageUrl(this.brand, Utils.PROPERTY_LOGO_HEADER, null);
  }

  get brandLogoImageUrl(): string {
    return this.brandLogoHeader ? this.brandLogoHeader : this.brandLogo;
  }

  private showStoreSelection() {
    this.selectionMode = true;
  }

  private hideStoreSelection() {
    this.selectionMode = false;
  }
}
